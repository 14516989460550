import Vue from "vue"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { toTitleCase } from "@evercam/shared/utils"

export type PdfElement = {
  type: PdfElementType
  content: any
  aspectRatio?: number
}

export type CreatePdfParams = {
  elements?: PdfElement[]
  pdfData?: Record<string, unknown>
  origin?: string
  fileName?: string
  orientation?: "p" | "portrait" | "l" | "landscape" | undefined
}

export enum PdfElementType {
  Svg = "svg",
  Image = "image",
  Table = "table",
}

declare module "vue/types/vue" {
  interface Vue {
    createPdf: (
      params: CreatePdfParams
    ) => Promise<{ file: Uint8Array; blob: Blob }>
    addHeader(
      pdf: jsPDF,
      origin: string,
      pdfData: Record<string, unknown>
    ): number
    addSvg(pdf: jsPDF, svgContent: string, yPosition: number): Promise<number>
    addImage(
      pdf: jsPDF,
      image: string,
      imageAspectRatio: number,
      yPosition: number,
      maxWidth: number,
      maxHeight: number
    ): number
    addTable(
      pdf: jsPDF,
      tableData: Record<string, string>[],
      yPosition: number
    ): void
  }
}

export default Vue.extend({
  name: "GeneratePdf",
  data() {
    return {
      margin: 20,
    }
  },
  methods: {
    async createPdf({
      elements = [],
      pdfData = {},
      origin = "",
      fileName = "",
      orientation = "p",
    }: CreatePdfParams = {}) {
      const pdf = new jsPDF({
        orientation,
        unit: "pt",
        format: "a4",
      })

      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * this.margin
      const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * this.margin

      let yPosition = 40

      // Add header
      yPosition += this.addHeader({ pdf, pdfData, origin }) + 15

      // Process elements in the order they were provided
      for (const element of elements) {
        switch (element.type) {
          case PdfElementType.Svg:
            const svgHeight = await this.addSvg({
              pdf,
              svgContent: element.content,
              yPosition,
            })
            yPosition += svgHeight + 15
            break

          case PdfElementType.Image:
            const imageHeight = this.addImage({
              pdf,
              image: element.content,
              imageAspectRatio: element.aspectRatio || 1,
              yPosition,
              maxWidth: pdfWidth,
              maxHeight: pdfHeight - yPosition,
            })
            yPosition += imageHeight + 15
            break

          case PdfElementType.Table:
            if (element.content?.length) {
              this.addTable({ pdf, tableData: element.content, yPosition })
              // Get the final Y position after the table is added
              // @ts-expect-error - previousAutoTable not recognized by jsPdf types
              const finalY = pdf.previousAutoTable.finalY
              yPosition = finalY + 15
            }
            break
        }
      }

      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          try {
            const pdfFile = pdf.output()
            const pdfBlob = new Blob([pdf.output("arraybuffer")], {
              type: "application/pdf",
            })
            pdf.save(fileName)
            resolve({ file: pdfFile, blob: pdfBlob })
          } catch (error) {
            reject(error)
          }
        })
      })
    },

    addHeader({
      pdf,
      pdfData,
      origin,
    }: {
      pdf: jsPDF
      origin: string
      pdfData: Record<string, unknown>
    }) {
      const startY = 40
      let currentY = startY

      pdf.setFontSize(12)
      pdf.setFont("helvetica", "bold")
      pdf.text(toTitleCase(origin), this.margin, currentY)
      currentY += 20

      pdf.setFontSize(8)
      pdf.setFont("helvetica", "normal")
      Object.entries(pdfData).forEach(([key, value]) => {
        pdf.setFont("helvetica", "bold")
        const keyText = `${toTitleCase(key)}: `
        const keyWidth = pdf.getStringUnitWidth(keyText) * pdf.getFontSize()
        pdf.text(keyText, this.margin, currentY)

        pdf.setFont("helvetica", "normal")
        pdf.text(String(value), this.margin + keyWidth, currentY)
        currentY += 15
      })

      const logoWidth = 160
      const logoHeight = 35
      const logoX = pdf.internal.pageSize.getWidth() - logoWidth - this.margin
      pdf.addImage(
        "/evercam_logo.png",
        "PNG",
        logoX,
        startY,
        logoWidth,
        logoHeight
      )

      return currentY - startY
    },

    async addSvg({
      pdf,
      yPosition,
      svgContent,
    }: {
      pdf: jsPDF
      svgContent: string
      yPosition: number
    }): Promise<number> {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      if (!ctx) {
        console.error("Could not get canvas context")

        return 0
      }

      const svgBlob = new Blob([svgContent.replace(/\r?\n|\r/g, "").trim()], {
        type: "image/svg+xml;charset=utf-8",
      })
      const svgUrl = URL.createObjectURL(svgBlob)

      try {
        const img = await new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image()
          img.onload = () => resolve(img)
          img.onerror = reject
          img.src = svgUrl
        })

        const availablePdfWidth =
          pdf.internal.pageSize.getWidth() - 2 * this.margin
        const desiredWidth = (availablePdfWidth * 96) / 72
        const aspectRatio = img.width / img.height
        const desiredHeight = desiredWidth / aspectRatio

        canvas.width = desiredWidth
        canvas.height = desiredHeight

        ctx.fillStyle = "#1f1f1f"
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

        const imgData = canvas.toDataURL("image/png")

        const pdfWidth = availablePdfWidth
        const pdfHeight = (desiredHeight * 72) / 96

        pdf.addImage(
          imgData,
          "PNG",
          this.margin,
          yPosition,
          pdfWidth,
          pdfHeight,
          undefined,
          "NONE"
        )

        return pdfHeight
      } catch (error) {
        console.error("Error adding SVG to PDF:", error)

        return 0
      } finally {
        URL.revokeObjectURL(svgUrl)
      }
    },

    addImage({
      pdf,
      image,
      yPosition,
      maxHeight,
      maxWidth,
      imageAspectRatio,
    }: {
      pdf: jsPDF
      image: string
      imageAspectRatio: number
      yPosition: number
      maxWidth: number
      maxHeight: number
    }) {
      let imgWidth = maxWidth
      let imgHeight = imgWidth / imageAspectRatio

      if (imageAspectRatio <= 1) {
        imgHeight = maxHeight
        imgWidth = imgHeight * imageAspectRatio
      }

      if (imgHeight > maxHeight) {
        imgHeight = maxHeight
        imgWidth = imgHeight * imageAspectRatio
      }

      pdf.addImage(image, "JPEG", this.margin, yPosition, imgWidth, imgHeight)

      return imgHeight
    },

    addTable({
      pdf,
      tableData,
      yPosition,
    }: {
      pdf: jsPDF
      tableData: Record<string, string>[]
      yPosition: number
    }) {
      const headers = Object.keys(tableData[0])
      const data = tableData.map((row) => Object.values(row))

      // @ts-expect-error - autotable not recognized by jsPdf types
      pdf.autoTable({
        startY: yPosition,
        head: [headers],
        body: data,
        styles: {
          font: "helvetica",
          fontSize: 7,
          cellPadding: 3,
          lineWidth: 0.1,
          textColor: 21,
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: 21,
          fontStyle: "bold",
          halign: "center",
          valign: "middle",
        },
        alternateRowStyles: {
          fillColor: [248, 248, 248],
        },
        margin: { left: this.margin, right: this.margin },
        columnStyles: headers.reduce((acc: Record<number, any>, _, index) => {
          acc[index] = {
            halign: "center",
            valign: "middle",
          }

          return acc
        }, {}),
      })
    },
  },
})
